.create-drop-button {
    cursor: pointer;
    color: #aaa;
    border-width: 2px !important;
    border-style: dashed !important;
    transition: all .3s ease-in-out !important;
}

.create-drop-button:hover {
    color: #555 !important;
    border-color: #555 !important;
}