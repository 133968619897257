.nav-link {
  color: #333;
}

.nav-link:focus, .nav-link:hover {
  color: #111;
}

.nav-tabs .nav-link.disabled {
  opacity: 0.5;
  cursor: default;
}