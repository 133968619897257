.check-user-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #3B3C49; */
  background: red;
  background: linear-gradient(to right, #3B3C49 0%, #4b4c5a 35%, #3B3C49 40%);
  z-index: 10000;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  /* animation-name: checkUserLoading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; */
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: checkUserLoading;
  animation-timing-function: ease-in-out;
}

.check-user-inner {
  margin: 0 auto;
}

@keyframes checkUserLoading {
  0% {
    background-position: -40vw 0
  }
  100% {
    background-position: 60vw 0
  }
}