/* Font Awesome */
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);

body {
  background-color: #f9f9f9;
}

html, body {
  height: 100%;
  margin: 0;
}

.visible {
  opacity: 1;
}

#root {
  padding-bottom: 50px;
  min-height: 100%;
  margin-bottom: -50px;
}

#footer, .footer-push {
  height: 50px;
}

#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3b3b3;
}