.copied-outer {
  position: relative;
}

.copied {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  opacity: 0;
  transition: all .3s ease-in-out;
  background-color: white;
  padding: 0 4px;
  border-radius: 6px;
  box-shadow: 0px 1px 5px -3px black;
}

.copied-visible {
  opacity: .9;
}