.drop-item-menu-button-wrapper {
  opacity: 0;
  position: absolute !important;
  top: 4px;
  right: 4px;
}

.drop-item-menu-button {
  transition: all .3s ease-in-out;
}

/* .drop-item:hover .drop-item-menu-button {
  opacity: 8;
} */

.drop-expanded {
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, .25);
}

.drop-item-menu-button-wrapper.hidden {
  display: none;
}