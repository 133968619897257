.thumbsContainer {
    margin-top: 2rem;
}

.dropzone_thumb {
    display: inline-block;
    width: 15%;
    margin: 0.8%;
    /* float: left; */
    /* background-color: #ffffff; */
    /* padding: 5px; */
}

.toolbar {
    margin: 30px 0;
}

@media only screen and (max-width: 1200px) {
    .dropzone_thumb {
        width: 15%;
        margin: 0.8%;
    }
}
@media only screen and (max-width: 992px) {
    .dropzone_thumb {
        width: 22%;
        margin: 1.5%;
    }
}
@media only screen and (max-width: 768px) {
    .dropzone_thumb {
        width: 22%;
        margin: 1.5%;
    }
}
@media only screen and (max-width: 576px) {
    .dropzone_thumb {
        width: 30%;
        margin: 1.66%;
    }
}

.dropzone_thumb_inner {
    padding-bottom: 100%;
    /* background-color: #3b3c49; */
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.dropzone_thumb_inner_div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.dropzone_thumb_inner_img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.thumbsContainer::after {
    content: '';
    display: block;
    clear: both;
}