// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff;
$gray-100: #f1f1f4;
$gray-200: #d6d7dc;
$gray-300: #afb0bb;
$gray-400: #9698a6;
$gray-500: #7e7f91;
$gray-600: #676979;
$gray-700: #505263;
$gray-800: #3b3c49;
$gray-900: #1e1e25;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$facebook: #4267b2;
$google: #db4437;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "gray-darker": $gray-900,
  "facebook": $facebook,
  "google": $google,
);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$darker: $gray-900;
$facebook: $facebook;
$google: $google;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "darker": $darker,
  "facebook": $facebook,
  "google": $google,
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;


// $nav-pills-border-radius: $border-radius;
// $nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $secondary;
